<template>
  <div class="max-w-xl" style="margin-top: -20%">
    <CCard v-if="shop" spacing>
      <CTypo tstyle="title3">
        {{ shop.cardTitle }}
      </CTypo>
      <div class="mt-2">
        <template v-for="shopProperty in shopProperties">
          <div
            v-if="shopProperty && shopProperty.length > 0"
            class="flex items-center mt-2"
            :key="shopProperty"
          >
            <CIcon
              name="check-circle-outline"
              class="text-green-600 mr-3"
              size="32"
            />
            <CTypo tstyle="title">
              {{ shopProperty }}
            </CTypo>
          </div>
        </template>
      </div>

      <form class="mt-4" @submit.prevent="onSubmit">
        <CTextField
          v-model="postalCodeInput"
          :label="$t('address.postalCode')"
          :data="matchtingPostalCodes"
          :debounce-typing="150"
          :clear-on-select="false"
          field="postalCode"
          required
          autocomplete-component
          @typing="searchPostalCode"
          @select="selectPostalCode"
          :custom-error="postalCodeError"
        >
          <template v-slot="props">
            {{ props.option.location.postalCode }} {{ props.option.location.city }}
          </template>
        </CTextField>
        <CSelect
          :value="selectedMerchantWasteInput"
          :label="$t('chooseWasteType')"
          required
          @input="selectMerchantWaste"
        >
          <option :value="null" disabled selected />
          <option
            v-for="merchantWaste in merchantWastes"
            :key="merchantWaste.id"
            :value="merchantWaste.id"
          >
            {{ merchantWaste.customName }}
          </option>
        </CSelect>
        <div class="flex justify-end">
          <CButton native-type="submit" variant="primary">
            {{ $t('continue') }}
          </CButton>
        </div>
      </form>
    </CCard>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import {
  DELIVERY_AREAS,
  MERCHANT,
  MERCHANT_WASTES,
  SELECTED_DELIVERY_AREA,
  SELECTED_MERCHANT_WASTE,
  SHOP,
} from '@/store/gettersTypes';
import { SET_SELECTED_DELIVERY_AREA, SET_SELECTED_MERCHANT_WASTE } from '@/store/mutationTypes';

export default {
  data: () => ({
    postalCodeInput: '',
    matchtingPostalCodes: [],
    selectedMerchantWasteInput: null,
    postalCodeError: null,
  }),

  computed: {
    ...mapGetters([
      DELIVERY_AREAS,
      MERCHANT,
      MERCHANT_WASTES,
      SELECTED_MERCHANT_WASTE,
      SELECTED_DELIVERY_AREA,
      SHOP,
    ]),
    shopProperties() {
      return [this.shop.cardLine1, this.shop.cardLine2, this.shop.cardLine3];
    },
  },

  watch: {
    selectedDeliveryArea() {
      this.postalCodeError = '';
    },
  },

  methods: {
    ...mapMutations([SET_SELECTED_DELIVERY_AREA, SET_SELECTED_MERCHANT_WASTE]),
    searchPostalCode(value) {
      this.matchtingPostalCodes = [];

      this.deliveryAreas.forEach((deliveryArea) => {
        if (
          deliveryArea.postalCodeId.match(
            `^.*${this.merchant.address.location.countryShort}-${value}.*$`,
          )
        ) {
          this.matchtingPostalCodes.push(deliveryArea);
        }
      });

      if (this.matchtingPostalCodes.length && value.length === 5) {
        this.selectPostalCode(this.matchtingPostalCodes[0]);
      } else if (!this.matchtingPostalCodes.length && value.length === 5) {
        this.postalCodeError = this.$t('postalCodeNotSupported');
      } else {
        this.setSelectedDeliveryArea(null);
        this.postalCodeError = '';
      }
    },
    selectPostalCode(deliveryArea) {
      if (deliveryArea) {
        this.setSelectedDeliveryArea(deliveryArea.id);
        setTimeout(() => {
          this.postalCodeInput = deliveryArea.location.postalCode;
        }, 0);
      } else {
        this.setSelectedDeliveryArea(null);
        this.postalCodeInput = '';
      }
    },
    selectMerchantWaste(marchentWasteId) {
      this.setSelectedMerchantWaste(marchentWasteId);
    },
    onSubmit() {
      if (!this.selectedDeliveryArea) {
        this.postalCodeError = this.$t('fillWithValidPostalCode');
      } else {
        this.$router.push({
          path: '/select-container',
          query: {
            merchantWasteId: this.selectedMerchantWaste.id,
            deliveryAreaId: this.selectedDeliveryArea.id,
          },
        });
      }
    },
  },
};
</script>
