<template>
  <div
    class="max-w-8xl mx-auto h-full -mt-6 lg:-mt-8"
    :style="style"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { SHOP } from '@/store/gettersTypes';
import { getUploadAssetUrl } from '@/utils/assets';

export default {
  computed: {
    ...mapGetters([SHOP]),
    style() {
      if (this.shop.coverPicture) {
        return {
          backgroundImage: `url(${getUploadAssetUrl(this.shop.coverPicture)})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          paddingBottom: '50vh',
        };
      }
      return {
        paddingBottom: '50vh',
        background: 'rgba(var(--primary), 0.33)',
      };
    },
  },
};
</script>
